import React from "react";
import Logo from "../Assets/Logo-01 1.png";
import {
  LuLayoutDashboard,
  LuPersonStanding,
  LuAppWindow,
  LuBuilding,
} from "react-icons/lu";
import { SiCodeproject, SiRazorpay } from "react-icons/si";
import { FcVoicePresentation, FcMoneyTransfer } from "react-icons/fc";
import { FaUserClock } from "react-icons/fa";
import { MdOutlineAccountBalance } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { FaNetworkWired } from "react-icons/fa";
import { BsWindowDock } from "react-icons/bs";
import { LuFiles } from "react-icons/lu";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { IoTicketOutline } from "react-icons/io5";
import { FaCode } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { MdQuiz } from "react-icons/md";
import { FaImages } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { TbCertificate } from "react-icons/tb";
import { CiDiscount1 } from "react-icons/ci";
import { useLocation } from "react-router-dom";

const SideBar = () => {
  const newlocation = useLocation();

  var role = sessionStorage.getItem("role") || localStorage.getItem("role");
  const logoutbtn = async () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };
  return (
    <div className="bg-black font-bold text-white w-2/12 ">
      <div className="flex flex-col items-center justify-between sidebarheight">
        <img src={Logo} alt="Logo" />
        {role === "admin" ? (
          <div className="flex mt-10 flex-col h-full w-4/6 items-center justify-evenly">
            <div className="h-full flex flex-col gap-4 pb-5">
              <a
                href="/#/dashboard"
                className={
                  newlocation.pathname === "/dashboard" ||
                  newlocation.pathname === "/"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <LuLayoutDashboard /> Dashboard
              </a>
              <a
                href="/#/students"
                className={
                  newlocation.pathname === "/students"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <LuPersonStanding />
                Students
              </a>
              <a
                href="/#/courses"
                className={
                  newlocation.pathname === "/courses"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <SiCodeproject />
                Courses
              </a>
              {/* <a
                href="/#/leads"
                className={
                  newlocation.pathname === "/leads"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <FcVoicePresentation />
                Leads
              </a> */}
              <a
                href="/#/coverpicture"
                className={
                  newlocation.pathname === "/coverpicture"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <FaImages />
                Cover Items
              </a>
              <a
                href="/#/entrolldata"
                className={
                  newlocation.pathname === "/entrolldata"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <FcVoicePresentation />
                Leads
              </a>
              <a
                href="/#/coupon"
                className={
                  newlocation.pathname === "/coupon"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <CiDiscount1 />
                Coupon
              </a>
              <a
                href="/#/contactuser"
                className={
                  newlocation.pathname === "/contactuser"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <FaUserClock />
                Contact User
              </a>
              <a
                href="/#/staff"
                className={
                  newlocation.pathname === "/staff"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <FcMoneyTransfer />
                Staff
              </a>
              <a
                href="/#/finance"
                className={
                  newlocation.pathname === "/finance"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <MdOutlineAccountBalance />
                Accounting
              </a>
              <a
                href="/#/certificategenerator"
                className={
                  newlocation.pathname === "/certificategenerator"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <TbCertificate />
                Certificate
              </a>
              <a
                href="/#/codingpractice"
                className={
                  newlocation.pathname === "/codingpractice"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <FaCode />
                Coding Practice
              </a>
              <a
                href="/#/attendance"
                className={
                  newlocation.pathname === "/attendance"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <HiOutlineCalendarDays />
                Attendance
              </a>
              <a
                href="/#/quiz"
                className={
                  newlocation.pathname === "/quiz"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <MdQuiz />
                Quiz
              </a>
              <a
                href="/#/facility"
                className={
                  newlocation.pathname === "/facility"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <LuBuilding />
                Facilitys
              </a>
              <a
                href="/#/payment"
                className={
                  newlocation.pathname === "/payment"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <SiRazorpay />
                Payments
              </a>
              <a
                href="/#/questiondata"
                className={
                  newlocation.pathname === "/questiondata"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <FaQuestionCircle />
                Questions
              </a>
              <a
                href="/#/appointments"
                className={
                  newlocation.pathname === "/appointments"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <LuAppWindow />
                Appointments
              </a>
              <a
                href="/#/ticket"
                className={
                  newlocation.pathname === "/ticket"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <IoTicketOutline />
                Ticket
              </a>
              <a
                href="/#/hire"
                className={
                  newlocation.pathname === "/hire"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <FaNetworkWired />
                Hire
              </a>
              <a
                href="##"
                className="flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                onClick={logoutbtn}
              >
                <CiLogout />
                Logout
              </a>
            </div>
          </div>
        ) : role === "student" ? (
          <div className="flex mt-10 flex-col h-full w-4/6 items-center justify-evenly">
            <div className="h-full flex flex-col gap-4">
              <a
                href="/#/mycourse"
                className={
                  newlocation.pathname === "/mycourse"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <BsWindowDock />
                My Course
              </a>
              <a
                href="/#/project"
                className={
                  newlocation.pathname === "/project"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <LuFiles />
                My Project
              </a>
              <a
                href="/#/myattendance"
                className={
                  newlocation.pathname === "/myattendance"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <HiOutlineCalendarDays />
                My Attendance
              </a>
              <a
                href="/#/codingpractice"
                className={
                  newlocation.pathname === "/codingpractice"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <FaCode />
                Coding Practice
              </a>
              <a
                href="/#/studentticket"
                className={
                  newlocation.pathname === "/studentticket"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <IoTicketOutline />
                Ticket
              </a>
              <a
                href="/#/profile"
                className={
                  newlocation.pathname === "/profile"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <CgProfile />
                Profile
              </a>
              <a
                href="/#/hire"
                className={
                  newlocation.pathname === "/hire"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <FaNetworkWired />
                Hire
              </a>
              <a
                href="##"
                className="flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                onClick={logoutbtn}
              >
                <CiLogout />
                Logout
              </a>
            </div>
          </div>
        ) : role === "staff" ? (
          <div className="flex mt-10 flex-col h-full w-4/6 items-center justify-evenly">
            <div className="h-full flex flex-col gap-4 pb-5">
              <a
                href="/#/students"
                className={
                  newlocation.pathname === "/students"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <LuPersonStanding />
                Students
              </a>

              <a
                href="/#/attendance"
                className={
                  newlocation.pathname === "/attendance"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <HiOutlineCalendarDays />
                Attendance
              </a>

              <a
                href="/#/facility"
                className={
                  newlocation.pathname === "/facility"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <LuBuilding />
                Facilitys
              </a>

              <a
                href="/#/profile"
                className={
                  newlocation.pathname === "/profile"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <CgProfile />
                Profile
              </a>
              <a
                href="##"
                className="flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                onClick={logoutbtn}
              >
                <CiLogout />
                Logout
              </a>
            </div>
          </div>
        ) : (
          <div className="flex mt-10 flex-col h-full w-4/6 items-center justify-evenly">
            <div className="h-full flex flex-col gap-4">
              <a
                href="/#/mycourse"
                className={
                  newlocation.pathname === "/mycourse"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <BsWindowDock />
                My Course
              </a>
              <a
                href="/#/profile"
                className={
                  newlocation.pathname === "/profile"
                    ? "bg-white text-black flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                    : "flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                }
              >
                <CgProfile />
                Profile
              </a>
              <a
                href="##"
                className="flex items-center gap-2 px-2 py-1 rounded hover:bg-white hover:text-black"
                onClick={logoutbtn}
              >
                <CiLogout />
                Logout
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBar;
