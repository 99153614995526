/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AttendancebyStudent, addAttendance } from "../Api/attendance";
import { FaRegCheckCircle } from "react-icons/fa";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const Myattendance = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");

  const columns = [
    { field: "id", headerName: "ID", width: 100 },

    {
      field: "name",
      headerName: "Student Name",
      width: 300,
    },
    {
      field: "date",
      headerName: "Check In Date",
      width: 250,
    },
    {
      field: "time",
      headerName: "Check In Time",
      width: 250,
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
    },
  ];

  useEffect(() => {
    getAttendancedata();
  }, [adminid]);
  const getAttendancedata = async () => {
    var myattendance = await AttendancebyStudent({ id: adminid });
    if (myattendance.length !== 0) {
      var alldata = [];
      for (var i = 0; i < myattendance.length; i++) {
        alldata.push({
          id: i + 1,
          name: myattendance[i].Student.name,
          date: myattendance[i].date,
          time: myattendance[i].time,
          status: myattendance[i].status === "true" ? "Present" : "Absent",
        });
      }
      setSelectedRows(alldata);
    }
  };
  const checkinbtn = async () => {
    if (adminid !== null) {
      var today = moment().format("YYYY-MM-DD");
      var myattendance = await AttendancebyStudent({ id: adminid });
      if (myattendance.length !== 0) {
        var checkattendance = await myattendance.filter((data) => {
          return data.date == today;
        });
        if (checkattendance.length == 0) {
          var craetedata = await addAttendance({ id: adminid, status: true });
          if (craetedata.message === "SUCCESS") {
            toast.success("Attendance added...", {
              autoClose: 2000,
              transition: Slide,
            });
            getAttendancedata();
          } else {
            toast.error(craetedata.message, {
              autoClose: 2000,
              transition: Slide,
            });
          }
        } else {
          toast.error("Already Attendance added today", {
            autoClose: 2000,
            transition: Slide,
          });
        }
      } else {
        var craetedata1 = await addAttendance({ id: adminid, status: true });
        if (craetedata1.message === "SUCCESS") {
          toast.success("Attendance added...", {
            autoClose: 2000,
            transition: Slide,
          });
          getAttendancedata();
        } else {
          toast.error(craetedata1.message, {
            autoClose: 2000,
            transition: Slide,
          });
        }
      }
    }
  };
  return (
    <div className="w-full px-14 min-h-screen">
      <div className="flex justify-between py-5 items-center">
        <h1 className="text-2xl py-4 font-black">Attendance </h1>
        <button
          className="bg-white text-balck border border-black hover:bg-black hover:text-white text-xl px-4 py-1 rounded flex gap-1 items-center"
          onClick={checkinbtn}
        >
          <FaRegCheckCircle className="text-green-500" /> Check In
        </button>
      </div>
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={selectedRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          align={"center"}
          pageSizeOptions={[10, 20, 50]}
          // checkboxSelection
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Myattendance;
