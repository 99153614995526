/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Logo from "../Assets/Logo-03 1.png";
import { Buffer } from "buffer";
import { Checkphonepe, statusphonepe } from "../Api/phonepe";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createStudents, updateStudents } from "../Api/student";
import Citylist from "../Pages/citylist.json";
import moment from "moment/moment";
import { createAmount_split } from "../Api/amount_split";
import { createPayment } from "../Api/payment";
import { viewallCourses } from "../Api/courses";
import { viewallcoupon } from "../Api/coupon";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
const { sha256 } = require("js-sha256");

const LinkCreate = () => {
  const [formdata, setformdata] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    course: "",
    dob: "",
    joiningdate: "",
    qualification: "",
    occupation: "",
    howyoufind: "",
    mode: "",
    english: "",
    location: "",
  });
  const [childWindow, setChildWindow] = React.useState(null);
  const [pricesplit, setpricesplit] = React.useState(null);
  const [paymentdisablebtn, setpaymentdisablebtn] = React.useState(false);
  const [statelist, setstatelist] = useState([]);
  const [citylist, setcitylist] = useState([]);
  const [amount, setamount] = useState(0);
  const [payamount, setpayamount] = useState(0);
  const [triggeramount, settriggeramount] = useState(0);
  const [updateid, setupdateid] = useState(null);
  const [coursedata, setcoursedata] = useState([]);
  const [addcoupon, setaddcoupon] = useState(false);
  const [allcoupondata, setallcoupondata] = useState([]);
  const [couponcode, setcouponcode] = useState("");
  const [singlecoupon, setsinglecoupon] = useState([]);
  const [finalerror, setfinalerror] = useState(false);
  const [courseamount, setcourseamount] = useState(0);
  const [discountamount, setdiscountamount] = useState(0);
  const [amountsplit, setamountsplit] = useState(0);

  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "course") {
      var checkdata = await coursedata.filter((data) => {
        return data.id == value;
      });
      if (checkdata.length !== 0) {
        setcourseamount(checkdata[0].offerprice);
        setformdata((values) => ({
          ...values,
          course: checkdata[0].title,
        }));
        setamountsplit(checkdata[0].amount_split);
        setpricesplit(null);
      }
    } else {
      setformdata((values) => ({ ...values, [name]: value }));
    }
  };
  const savebtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please enter Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.city.length === 0) {
      toast.error("Please enter City...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.course.length === 0) {
      toast.error("Please Select Course...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.dob.length === 0) {
      toast.error("Please select Date Of birth...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.qualification.length === 0) {
      toast.error("Please enter Qualification...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.mode.length === 0) {
      toast.error("Please select Mode Of class...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (pricesplit === null) {
      toast.error("Please Select Amount Split...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      // setpaymentdisablebtn(true);
      formdata["payment_status"] = "false";
      formdata["pendingfees"] = 0;
      formdata["paidfees"] = 0;
      var newamount = 0;
      if (amountsplit == 3) {
        if (pricesplit == 1) {
          setpayamount(
            Math.round(Number(amount) - (Number(amount) * 10) / 100)
          );
          formdata["fees"] = Math.round(
            Number(amount) - (Number(amount) * 10) / 100
          );
          settriggeramount(
            Math.round(Number(amount) - (Number(amount) * 10) / 100)
          );
          newamount = Math.round(Number(amount) - (Number(amount) * 10) / 100);
          sessionStorage.setItem(
            "payamount",
            Math.round(Number(amount) - (Number(amount) * 10) / 100)
          );
          sessionStorage.setItem(
            "triggeramount",
            Math.round(Number(amount) - (Number(amount) * 10) / 100)
          );
        } else if (pricesplit == 2) {
          setpayamount(Math.round(Number(amount) - (Number(amount) * 5) / 100));
          settriggeramount(
            Math.round((Number(amount) - (Number(amount) * 5) / 100) / 2)
          );
          formdata["fees"] = Math.round(
            Number(amount) - (Number(amount) * 5) / 100
          );
          newamount = Math.round(
            (Number(amount) - (Number(amount) * 5) / 100) / 2
          );
          sessionStorage.setItem(
            "payamount",
            Math.round(Number(amount) - (Number(amount) * 5) / 100)
          );
          sessionStorage.setItem(
            "triggeramount",
            Math.round((Number(amount) - (Number(amount) * 5) / 100) / 2)
          );
        } else if (pricesplit == 3) {
          setpayamount(Math.round(Number(amount) - (Number(amount) * 0) / 100));
          settriggeramount(
            Math.round((Number(amount) - (Number(amount) * 0) / 100) / 3)
          );
          formdata["fees"] = Math.round(
            Number(amount) - (Number(amount) * 0) / 100
          );
          newamount = Math.round(
            (Number(amount) - (Number(amount) * 0) / 100) / 3
          );
          sessionStorage.setItem(
            "payamount",
            Math.round(Number(amount) - (Number(amount) * 0) / 100)
          );
          sessionStorage.setItem(
            "triggeramount",
            Math.round((Number(amount) - (Number(amount) * 0) / 100) / 3)
          );
        }
      } else if (amountsplit == 2) {
        if (pricesplit == 1) {
          setpayamount(
            Math.round(Number(amount) - (Number(amount) * 10) / 100)
          );
          formdata["fees"] = Math.round(
            Number(amount) - (Number(amount) * 10) / 100
          );
          settriggeramount(
            Math.round(Number(amount) - (Number(amount) * 10) / 100)
          );
          newamount = Math.round(Number(amount) - (Number(amount) * 10) / 100);
          sessionStorage.setItem(
            "payamount",
            Math.round(Number(amount) - (Number(amount) * 10) / 100)
          );
          sessionStorage.setItem(
            "triggeramount",
            Math.round(Number(amount) - (Number(amount) * 10) / 100)
          );
        } else if (pricesplit == 2) {
          setpayamount(Math.round(Number(amount) - (Number(amount) * 0) / 100));
          settriggeramount(
            Math.round((Number(amount) - (Number(amount) * 0) / 100) / 2)
          );
          formdata["fees"] = Math.round(
            Number(amount) - (Number(amount) * 0) / 100
          );
          newamount = Math.round(
            (Number(amount) - (Number(amount) * 0) / 100) / 2
          );
          sessionStorage.setItem(
            "payamount",
            Math.round(Number(amount) - (Number(amount) * 0) / 100)
          );
          sessionStorage.setItem(
            "triggeramount",
            Math.round((Number(amount) - (Number(amount) * 0) / 100) / 2)
          );
        }
      } else if (amountsplit == 1) {
        if (pricesplit == 1) {
          setpayamount(Math.round(Number(amount) - (Number(amount) * 0) / 100));
          formdata["fees"] = Math.round(
            Number(amount) - (Number(amount) * 0) / 100
          );
          settriggeramount(
            Math.round(Number(amount) - (Number(amount) * 0) / 100)
          );
          newamount = Math.round(Number(amount) - (Number(amount) * 0) / 100);
          sessionStorage.setItem(
            "payamount",
            Math.round(Number(amount) - (Number(amount) * 0) / 100)
          );
          sessionStorage.setItem(
            "triggeramount",
            Math.round(Number(amount) - (Number(amount) * 0) / 100)
          );
        }
      }

      formdata["password"] = "Test@123";
      sessionStorage.setItem("amount", amount);
      sessionStorage.setItem("pricesplit", JSON.stringify(pricesplit));
      sessionStorage.setItem("formdata", JSON.stringify(formdata));
      sessionStorage.setItem("fees", JSON.stringify(formdata));
      sessionStorage.setItem("amountsplit", amountsplit);
      const txnid = `Txn_${Math.floor(Math.random() * 1000000)}`;
      const response = await axios.post(
        "https://api.codepurple.in/payu/create",
        {
          amount: newamount,
          firstname: formdata.name,
          email: formdata.email,
          productinfo: "Course Purchase",
          txnid: txnid,
        }
      );
      if (response.data.success) {
        const { hash, key, surl, furl } = response.data;
        const payUForm = document.createElement("form");
        payUForm.action = "https://secure.payu.in/_payment";
        payUForm.method = "POST";
        payUForm.innerHTML = `
          <input type="hidden" name="key" value="${key}" />
          <input type="hidden" name="txnid" value="${txnid}" />
          <input type="hidden" name="amount" value="${newamount}" />
          <input type="hidden" name="productinfo" value="Course Purchase" />
          <input type="hidden" name="firstname" value="${formdata.name}" />
          <input type="hidden" name="email" value="${formdata.email}" />
          <input type="hidden" name="phone" value="${formdata.phone}" />
          <input type="hidden" name="hash" value="${hash}" />
          <input type="hidden" name="surl" value="${surl}"  />
          <input type="hidden" name="furl" value="${furl}"  />
        `;
        document.body.appendChild(payUForm);
        payUForm.submit();
      }
    }
  };
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (childWindow && childWindow.closed) {
        checkstatus();
        setpaymentdisablebtn(false);
        clearInterval(intervalId);
      }
    }, 1000);
    setstatelist(Citylist.states);
    return () => {
      clearInterval(intervalId);
    };
  }, [childWindow]);
  const checkstatus = async () => {
    var orderid = localStorage.getItem("orderId");
    const hash = sha256(
      `/pg/v1/status/VIMIXONLINE/${orderid}951b8a36-c6c8-4df7-a442-4f159283a99f`
    );
    var finalhash = `${hash}###1`;
    var statuspayment = await statusphonepe({
      hash: finalhash,
      merchantId: "VIMIXONLINE",
      orderid: orderid,
    });
    if (statuspayment.message === "Your payment is successful.") {
      for (var i = 0; i < pricesplit; i++) {
        if (pricesplit == 1) {
          var newdata = {
            student: "",
            amount: Number(amount) - (Number(amount) * 10) / 100,
            duedate: moment().format("YYYY-MM-DD"),
            status: "true",
          };
          createAmount_split(newdata);
        } else if (pricesplit == 2) {
          var newdata2 = {
            student: updateid,
            amount: Math.round(
              (Number(amount) - (Number(amount) * 5) / 100) / 2
            ),
            duedate: moment()
              .add(30 * i, "days")
              .format("YYYY-MM-DD"),
            status: i == 0 ? "true" : "false",
          };
          createAmount_split(newdata2);
        } else if (pricesplit == 3) {
          var newdata3 = {
            student: updateid,
            amount: Math.round(
              (Number(amount) - (Number(amount) * 0) / 100) / 3
            ),
            duedate: moment()
              .add(30 * i, "days")
              .format("YYYY-MM-DD"),
            status: i == 0 ? "true" : "false",
          };
          createAmount_split(newdata3);
        }
      }
      if (pricesplit == 1) {
        var newobj = {
          id: updateid,
          pendingfees: 0,
          paidfees: payamount,
        };
        var paymentobj = {
          student: updateid,
          cost: payamount,
          date: moment().format("YYYY-MM-DD"),
          pendingfees: 0,
          mode: "Online",
        };
        createPayment(paymentobj);
        updateStudents(newobj);
      } else if (pricesplit == 2) {
        var newobj2 = {
          id: updateid,
          pendingfees: Number(payamount / 2),
          paidfees: Number(payamount / 2),
        };
        var paymentobj2 = {
          student: updateid,
          cost: Number(payamount / 2),
          date: moment().format("YYYY-MM-DD"),
          pendingfees: Number(payamount / 2),
          mode: "Online",
        };
        createPayment(paymentobj2);
        updateStudents(newobj2);
      } else if (pricesplit == 3) {
        var newobj3 = {
          id: updateid,
          pendingfees: Number(payamount) - Number(payamount / 3),
          paidfees: Number(payamount / 3),
        };
        var paymentobj3 = {
          student: updateid,
          cost: Number(payamount / 3),
          date: moment().format("YYYY-MM-DD"),
          pendingfees: Number(payamount) - Number(payamount / 3),
          mode: "Online",
        };
        createPayment(paymentobj3);
        updateStudents(newobj3);
      }
      toast.success("Account added Successfully...", {
        autoClose: 2000,
        transition: Slide,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      for (var i = 0; i < pricesplit; i++) {
        if (pricesplit == 1) {
          var newdata = {
            student: "",
            amount: Number(amount) - (Number(amount) * 10) / 100,
            duedate: moment().format("YYYY-MM-DD"),
            status: "false",
          };
          createAmount_split(newdata);
        } else if (pricesplit == 2) {
          var newdata2 = {
            student: updateid,
            amount: Math.round(
              (Number(amount) - (Number(amount) * 5) / 100) / 2
            ),
            duedate: moment()
              .add(30 * i, "days")
              .format("YYYY-MM-DD"),
            status: "false",
          };
          createAmount_split(newdata2);
        } else if (pricesplit == 3) {
          var newdata3 = {
            student: updateid,
            amount: Math.round(
              (Number(amount) - (Number(amount) * 0) / 100) / 3
            ),
            duedate: moment()
              .add(30 * i, "days")
              .format("YYYY-MM-DD"),
            status: "false",
          };
          createAmount_split(newdata3);
        }
      }
      var newobj = {
        id: updateid,
        pendingfees: Number(amount),
        paidfees: 0,
      };
      updateStudents(newobj);
      toast.error("Payment failed...", {
        autoClose: 2000,
        transition: Slide,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };
  const changesplit = (e) => {
    setpricesplit(e.target.value);
  };
  const changestate = async (e) => {
    var checkdata = await Citylist.states.filter((data) => {
      return data.state == e.target.value;
    });
    if (checkdata.length !== 0) {
      setcitylist(checkdata[0].districts);
    }
  };
  useEffect(() => {
    getCoursesdata();
  }, []);
  const getCoursesdata = async () => {
    var allcourse = await viewallCourses();
    var alldata = await viewallcoupon();
    setcoursedata(allcourse);
    setallcoupondata(alldata);
  };
  const submitbtn = async () => {
    if (courseamount !== 0) {
      setfinalerror(false);
      if (allcoupondata.length !== 0) {
        var checkdata = await allcoupondata.filter((data) => {
          return data.name == couponcode;
        });
        if (checkdata.length !== 0) {
          setsinglecoupon(checkdata);
          setdiscountamount(checkdata[0].amount);
        } else {
          setfinalerror(true);
          setdiscountamount(0);
        }
      } else {
        setfinalerror(true);
        setdiscountamount(0);
      }
    } else {
      toast.error("Please Select Course...", {
        autoClose: 2000,
        transition: Slide,
      });
    }
  };
  useEffect(() => {
    CalculateAmount();
  }, [courseamount, discountamount]);
  const CalculateAmount = () => {
    var totalamount = Number(courseamount) - Number(discountamount);
    setamount(totalamount);
  };
  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center mt-8">
      <div className="md:w-4/12 w-full bg-white rounded-xl px-8 py-4 flex flex-col justify-center">
        <div className="w-full bg-white rounde shadow-2xl p-7 my-5 linkcreatediv">
          <div className="flex flex-wrap my-2 justify-center gap-6 md:px-32">
            <div className="flex flex-col justify-center items-center">
              <p className="md:text-xl font-bold text-sm w-full mt-2 text-center">
                Welcome to
              </p>
              <img
                className="flex-shrink-0 object-cover object-center"
                src={Logo}
                alt=""
              />
            </div>
          </div>
          <div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Name
              </h1>
              <input
                placeholder="Name"
                type="text"
                name="name"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Email
              </h1>
              <input
                placeholder="Email"
                type="text"
                name="email"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Phone Number
              </h1>
              <input
                placeholder="Phone Number"
                type="text"
                name="phone"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                State
              </h1>
              <select
                name="state"
                id="state"
                className="border-2 p-2 w-full py-2 rounded"
                required=""
                defaultValue={formdata.state}
                onChange={changestate}
              >
                <option value="">Select State</option>
                {statelist.length !== 0
                  ? statelist.map((data, index) => (
                      <option value={data.state} key={index}>
                        {data.state}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                City
              </h1>
              <select
                name="city"
                id="city"
                className="border-2 p-2 w-full py-2 rounded"
                required=""
                defaultValue={formdata.city}
                onChange={handleChange}
              >
                <option value="">Select City</option>
                {citylist.length !== 0
                  ? citylist.map((data, index) => (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Course
              </h1>
              <select
                name="course"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
              >
                <option value="">Select Course</option>
                {coursedata.length !== 0
                  ? coursedata.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.title}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Date Of Birth
              </h1>
              <input
                placeholder="Email"
                type="date"
                name="dob"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg">Joining Date</h1>
              <input
                placeholder="Joining Date"
                type="date"
                name="joiningdate"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Qualification
              </h1>
              <input
                placeholder="Qualification"
                type="text"
                name="qualification"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg">Occupation</h1>
              <select
                name="occupation"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.occupation}
              >
                <option>Select Occupation</option>
                <option value="Student">Student</option>
                <option value="Unemployed">Unemployed</option>
                <option value="Working Professional">
                  Working Professional
                </option>
              </select>
            </div>
            <div className="py-3">
              <h1 className="text-lg">How you find us ?</h1>
              <select
                name="howyoufind"
                id=""
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.howyoufind}
              >
                <option value="">Select</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Google">Google</option>
                <option value="Local Advertisement">Local Advertisement</option>
                <option value="Recommendation from a friend">
                  Recommendation from a friend
                </option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Mode Of class
              </h1>
              <select
                name="mode"
                id=""
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
              >
                <option value="">Select Mode</option>
                <option value="Online">Online</option>
                <option value="Offline">Offline</option>
              </select>
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Preferred course location
              </h1>
              <select
                name="location"
                id=""
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
              >
                <option value="">Select location</option>
                <option value="Coimbatore">Coimbatore</option>
                <option value="Sivakasi">Sivakasi</option>
              </select>
            </div>

            <div className="py-3">
              <div className="flex gap-2 items-center">
                <h1 className="text-lg">Apply Coupon</h1>
                <input
                  type="checkbox"
                  onChange={() => {
                    setaddcoupon(!addcoupon);
                    setcouponcode("");
                    setsinglecoupon([]);
                    setdiscountamount(0);
                  }}
                  checked={addcoupon == true}
                />
              </div>
              {addcoupon == true ? (
                <div className="flex gap-2 items-center">
                  <input
                    placeholder="Enter Coupon Code"
                    type="text"
                    name="qualification"
                    className="border-2 p-2 w-[70%] py-2 rounded-xl"
                    onChange={(e) => {
                      setcouponcode(e.target.value);
                    }}
                    id="couponcode"
                    defaultValue={couponcode}
                  />
                  <button
                    className="w-[30%] bg-black text-white font-md py-2 rounded-xl"
                    onClick={submitbtn}
                  >
                    Submit
                  </button>
                </div>
              ) : null}
            </div>
            {singlecoupon.length !== 0 ? (
              <div className="flex bg-green-600 w-fit gap-2 items-center rounded-full px-4 py-1">
                <h1 className="text-white">{singlecoupon[0].name}</h1>
                <IoMdClose
                  className="text-white font-bold text-xl cursor-pointer"
                  onClick={() => {
                    setcouponcode("");
                    setsinglecoupon([]);
                    document.getElementById("couponcode").value = "";
                    setdiscountamount(0);
                  }}
                />
              </div>
            ) : null}
            {finalerror && (
              <p className="text-red-500 text-sm">Invalid coupon code.</p>
            )}
            {formdata.location == "Sivakasi" ? (
              <>
                {" "}
                <div className="py-3">
                  <div className="flex gap-2 items-center">
                    <h1 className="text-lg">Select Amount Split </h1>
                    {amount == 0 ? null : <p>(Course Amount : {amount})</p>}
                  </div>

                  {amountsplit == 3 ? (
                    <div className="flex gap-2 justify-between mt-2">
                      <div>
                        <input
                          type="radio"
                          id="1 split"
                          name="split"
                          value="1"
                          checked={pricesplit == 1}
                          onChange={changesplit}
                        />
                        <label for="1 split" className="ml-2">
                          1 split
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="2 split"
                          name="split"
                          value="2"
                          checked={pricesplit == 2}
                          onChange={changesplit}
                        />
                        <label for="2 split" className="ml-2">
                          2 split
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="3 split"
                          name="split"
                          value="3"
                          checked={pricesplit == 3}
                          onChange={changesplit}
                        />
                        <label for="3 split" className="ml-2">
                          3 split
                        </label>
                      </div>
                    </div>
                  ) : amountsplit == 2 ? (
                    <div className="flex gap-2 justify-between mt-2">
                      <div>
                        <input
                          type="radio"
                          id="1 split"
                          name="split"
                          value="1"
                          checked={pricesplit == 1}
                          onChange={changesplit}
                        />
                        <label for="1 split" className="ml-2">
                          1 split
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="2 split"
                          name="split"
                          value="2"
                          checked={pricesplit == 2}
                          onChange={changesplit}
                        />
                        <label for="2 split" className="ml-2">
                          2 split
                        </label>
                      </div>
                    </div>
                  ) : amountsplit == 1 ? (
                    <div className="flex gap-2 justify-between mt-2">
                      <div>
                        <input
                          type="radio"
                          id="1 split"
                          name="split"
                          value="1"
                          checked={pricesplit == 1}
                          onChange={changesplit}
                        />
                        <label for="1 split" className="ml-2">
                          1 split
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>
                {amountsplit == 3 ? (
                  <div className="py-3">
                    {pricesplit == 1 ? (
                      <>
                        <table className="w-full text-center">
                          <tr>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Amount</th>
                          </tr>
                          <tr>
                            <td>1st Payment</td>
                            <td>{moment().format("DD-MM-YYYY")}</td>
                            <td>
                              ₹
                              {Math.round(
                                Number(amount) - (Number(amount) * 10) / 100
                              )}
                              /-
                            </td>
                          </tr>

                          <tr>
                            <td colspan="2">Total</td>
                            <td>
                              ₹
                              {Math.round(
                                Number(amount) - (Number(amount) * 10) / 100
                              )}
                              /-{" "}
                              <span className="text-green-500">(10% OFF)</span>
                            </td>
                          </tr>
                        </table>
                      </>
                    ) : null}
                    {pricesplit == 2 ? (
                      <>
                        <table className="w-full text-center">
                          <tr>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Amount</th>
                          </tr>
                          <tr>
                            <td>1st Payment</td>
                            <td>{moment().format("DD-MM-YYYY")}</td>
                            <td>
                              ₹
                              {Math.round(
                                (Number(amount) - (Number(amount) * 5) / 100) /
                                  2
                              )}
                              /-
                            </td>
                          </tr>
                          <tr>
                            <td>2nd Payment</td>
                            <td>
                              {moment().add(30, "days").format("DD-MM-YYYY")}
                            </td>
                            <td>
                              ₹
                              {Math.round(
                                (Number(amount) - (Number(amount) * 5) / 100) /
                                  2
                              )}
                              /-
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">Total</td>
                            <td>
                              ₹
                              {Math.round(
                                Number(amount) - (Number(amount) * 5) / 100
                              )}
                              /-{" "}
                              <span className="text-green-500">(5% OFF)</span>
                            </td>
                          </tr>
                        </table>
                      </>
                    ) : null}
                    {pricesplit == 3 ? (
                      <>
                        <table className="w-full text-center">
                          <tr>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Amount</th>
                          </tr>
                          <tr>
                            <td>1st Payment</td>
                            <td>{moment().format("DD-MM-YYYY")}</td>
                            <td>
                              ₹
                              {Math.round(
                                (Number(amount) - (Number(amount) * 0) / 100) /
                                  3
                              )}
                              /-
                            </td>
                          </tr>
                          <tr>
                            <td>2nd Payment</td>
                            <td>
                              {moment().add(30, "days").format("DD-MM-YYYY")}
                            </td>
                            <td>
                              ₹
                              {Math.round(
                                (Number(amount) - (Number(amount) * 0) / 100) /
                                  3
                              )}
                              /-
                            </td>
                          </tr>
                          <tr>
                            <td>3rd Payment</td>
                            <td>
                              {moment().add(60, "days").format("DD-MM-YYYY")}
                            </td>
                            <td>
                              ₹
                              {Math.round(
                                (Number(amount) - (Number(amount) * 0) / 100) /
                                  3
                              )}
                              /-
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">Total</td>
                            <td>
                              ₹ {amount} /-{" "}
                              <span className="text-red-500"></span>
                            </td>
                          </tr>
                        </table>
                      </>
                    ) : null}
                  </div>
                ) : amountsplit == 2 ? (
                  <div className="py-3">
                    {pricesplit == 1 ? (
                      <>
                        <table className="w-full text-center">
                          <tr>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Amount</th>
                          </tr>
                          <tr>
                            <td>1st Payment</td>
                            <td>{moment().format("DD-MM-YYYY")}</td>
                            <td>
                              ₹
                              {Math.round(
                                Number(amount) - (Number(amount) * 10) / 100
                              )}
                              /-
                            </td>
                          </tr>

                          <tr>
                            <td colspan="2">Total</td>
                            <td>
                              ₹
                              {Math.round(
                                Number(amount) - (Number(amount) * 10) / 100
                              )}
                              /-{" "}
                              <span className="text-green-500">(10% OFF)</span>
                            </td>
                          </tr>
                        </table>
                      </>
                    ) : null}
                    {pricesplit == 2 ? (
                      <>
                        <table className="w-full text-center">
                          <tr>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Amount</th>
                          </tr>
                          <tr>
                            <td>1st Payment</td>
                            <td>{moment().format("DD-MM-YYYY")}</td>
                            <td>
                              ₹
                              {Math.round(
                                (Number(amount) - (Number(amount) * 0) / 100) /
                                  2
                              )}
                              /-
                            </td>
                          </tr>
                          <tr>
                            <td>2nd Payment</td>
                            <td>
                              {moment().add(30, "days").format("DD-MM-YYYY")}
                            </td>
                            <td>
                              ₹
                              {Math.round(
                                (Number(amount) - (Number(amount) * 0) / 100) /
                                  2
                              )}
                              /-
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">Total</td>
                            <td>
                              ₹
                              {Math.round(
                                Number(amount) - (Number(amount) * 0) / 100
                              )}
                              /-
                            </td>
                          </tr>
                        </table>
                      </>
                    ) : null}
                  </div>
                ) : amountsplit == 1 ? (
                  <div className="py-3">
                    {pricesplit == 1 ? (
                      <>
                        <table className="w-full text-center">
                          <tr>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Amount</th>
                          </tr>
                          <tr>
                            <td>1st Payment</td>
                            <td>{moment().format("DD-MM-YYYY")}</td>
                            <td>
                              ₹
                              {Math.round(
                                Number(amount) - (Number(amount) * 0) / 100
                              )}
                              /-
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">Total</td>
                            <td>
                              ₹
                              {Math.round(
                                Number(amount) - (Number(amount) * 0) / 100
                              )}
                              /-
                            </td>
                          </tr>
                        </table>
                      </>
                    ) : null}
                  </div>
                ) : null}
              </>
            ) : formdata.location == "Coimbatore" ? (
              <>
                <div className="flex gap-4 mt-2">
                  <div>
                    <input
                      type="radio"
                      id="1 split"
                      name="split"
                      value="1"
                      checked={pricesplit == 1}
                      onChange={changesplit}
                    />
                    <label for="1 split" className="ml-2">
                      1 split
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="EMI"
                      name="split"
                      value="EMI"
                      checked={pricesplit == "EMI"}
                      onChange={changesplit}
                    />
                    <label for="EMI" className="ml-2">
                      EMI
                    </label>
                  </div>
                </div>
                <div className="py-3">
                  {pricesplit == 1 ? (
                    <>
                      <table className="w-full text-center">
                        <tr>
                          <th>Type</th>
                          <th>Date</th>
                          <th>Amount</th>
                        </tr>
                        <tr>
                          <td>1st Payment</td>
                          <td>{moment().format("DD-MM-YYYY")}</td>
                          <td>
                            ₹
                            {Math.round(
                              Number(amount) - (Number(amount) * 0) / 100
                            )}
                            /-
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">Total</td>
                          <td>
                            ₹
                            {Math.round(
                              Number(amount) - (Number(amount) * 0) / 100
                            )}
                            /-
                          </td>
                        </tr>
                      </table>
                    </>
                  ) : null}
                </div>
              </>
            ) : null}

            <div className="py-3">
              {paymentdisablebtn === true ? (
                <button className="w-full bg-black text-white font-md py-3 rounded-xl">
                  Please Wait...
                </button>
              ) : (
                <button
                  className="w-full bg-black text-white font-md py-3 rounded-xl"
                  onClick={savebtn}
                >
                  Pay & Continue
                </button>
              )}
            </div>
            <div className="flex gap-2 justify-center">
              <a href="/#/terms" className="underline text-center">
                Terms & Conditions
              </a>
              <a href="/#/refundpolicy" className="underline text-center">
                Cancellation & Refund Policy
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LinkCreate;
