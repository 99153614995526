/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  addAttendance,
  AttendancebyStudent,
  TodayAttancedata,
} from "../Api/attendance";
import { ViewAllStudents } from "../Api/student";
import moment from "moment";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Attendance = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [allRows, setallRows] = useState([]);
  const [todayattenacedata, settodayattenacedata] = useState([]);

  const columns = [
    { field: "id", headerName: "ID", width: 80 },
    { field: "name", headerName: "Student Name", width: 220 },
    { field: "course", headerName: "Course", width: 330 },
    { field: "joiningdate", headerName: "Joining Date", width: 150 },
    { field: "mode", headerName: "Mode Of Class", width: 150 },
    {
      field: "status", // Add a new column for attendance status
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        const attendanceToday = todayattenacedata.find(
          (entry) => entry.student == params.row.data
        );
        return (
          <span>
            {attendanceToday
              ? attendanceToday.status === "true"
                ? "Present"
                : "Absent"
              : "Not Marked"}
          </span>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        // Find if attendance is already marked
        const attendanceToday = todayattenacedata.find(
          (entry) => entry.student == params.row.data
        );

        const isPresent = attendanceToday
          ? attendanceToday.status === "true"
          : false;
        const isAbsent = attendanceToday
          ? attendanceToday.status === "false"
          : false;

        return (
          <div className="flex gap-2">
            {!isAbsent && ( // Only show "Present" button if not absent
              <button
                className={`py-2 px-5 rounded ${
                  isPresent
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-green-500 hover:bg-green-800 text-white"
                }`}
                onClick={() => Presentbtn(params.row)}
                disabled={isPresent || isAbsent}
              >
                Present
              </button>
            )}
            {!isPresent && ( // Only show "Absent" button if not present
              <button
                className={`py-2 px-5 rounded ${
                  isAbsent
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-black hover:bg-gray-800 text-white"
                }`}
                onClick={() => Absentbtn(params.row)}
                disabled={isPresent || isAbsent}
              >
                Absent
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const Presentbtn = async (data) => {
    var adminid = data.data;
    if (adminid !== null) {
      var today = moment().format("YYYY-MM-DD");
      var myattendance = await AttendancebyStudent({ id: adminid });
      if (myattendance.length !== 0) {
        var checkattendance = await myattendance.filter((data) => {
          return data.date == today;
        });
        if (checkattendance.length == 0) {
          var craetedata = await addAttendance({ id: adminid, status: true });
          if (craetedata.message === "SUCCESS") {
            toast.success("Attendance added...", {
              autoClose: 2000,
              transition: Slide,
            });
            getAttendancedata();
          } else {
            toast.error(craetedata.message, {
              autoClose: 2000,
              transition: Slide,
            });
          }
        } else {
          toast.error("Already Attendance added today", {
            autoClose: 2000,
            transition: Slide,
          });
        }
      } else {
        var craetedata1 = await addAttendance({ id: adminid, status: true });
        if (craetedata1.message === "SUCCESS") {
          toast.success("Attendance added...", {
            autoClose: 2000,
            transition: Slide,
          });
          getAttendancedata();
        } else {
          toast.error(craetedata1.message, {
            autoClose: 2000,
            transition: Slide,
          });
        }
      }
    }
  };

  const Absentbtn = async (data) => {
    var adminid = data.data;
    if (adminid !== null) {
      var today = moment().format("YYYY-MM-DD");
      var myattendance = await AttendancebyStudent({ id: adminid });
      if (myattendance.length !== 0) {
        var checkattendance = await myattendance.filter((data) => {
          return data.date == today;
        });
        if (checkattendance.length == 0) {
          var craetedata = await addAttendance({ id: adminid, status: false });
          if (craetedata.message === "SUCCESS") {
            toast.success("Attendance added...", {
              autoClose: 2000,
              transition: Slide,
            });
            getAttendancedata();
          } else {
            toast.error(craetedata.message, {
              autoClose: 2000,
              transition: Slide,
            });
          }
        } else {
          toast.error("Already Attendance added today", {
            autoClose: 2000,
            transition: Slide,
          });
        }
      } else {
        var craetedata1 = await addAttendance({ id: adminid, status: false });
        if (craetedata1.message === "SUCCESS") {
          toast.success("Attendance added...", {
            autoClose: 2000,
            transition: Slide,
          });
          getAttendancedata();
        } else {
          toast.error(craetedata1.message, {
            autoClose: 2000,
            transition: Slide,
          });
        }
      }
    }
  };

  useEffect(() => {
    getAttendancedata();
  }, []);

  const getAttendancedata = async () => {
    var todaydata = await TodayAttancedata();
    var allstudent = await ViewAllStudents();
    if (allstudent.length !== 0) {
      allstudent.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      var alldata = [];
      for (var i = 0; i < allstudent.length; i++) {
        alldata.push({
          id: i + 1,
          name: allstudent[i].name,
          course: allstudent[i].course,
          joiningdate: allstudent[i].joiningdate,
          mode: allstudent[i].mode,
          data: allstudent[i].id,
        });
      }
      setSelectedRows(alldata);
      setallRows(alldata);
    } else {
      setallRows([]);
      setSelectedRows([]);
    }
    settodayattenacedata(todaydata);
  };

  const searchuser = async (e) => {
    if (e.length !== 0) {
      if (allRows.length !== 0) {
        const filterdata = allRows.filter((skill) => {
          return skill.name.toLowerCase().includes(e.toLowerCase());
        });
        setSelectedRows(filterdata);
      } else {
        setSelectedRows([]);
      }
    } else {
      setSelectedRows(allRows);
    }
  };
  return (
    <div className="w-full px-14 min-h-screen">
      <div className="flex justify-between py-5 items-center">
        <h1 className="text-2xl py-4 font-black">Add Student Attendance </h1>
      </div>
      <div className="pb-3 w-full">
        <input
          name="student"
          className="border-2 p-2 w-full py-2 rounded"
          onChange={(e) => {
            searchuser(e.target.value);
          }}
          placeholder="Search Student Name"
        />
      </div>
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={selectedRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 30 },
            },
          }}
          align={"center"}
          rowsPerPageOptions={[30, 50, 100]} // Provide options for the user to change
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Attendance;
