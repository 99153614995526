/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  ViewAllStudents,
  createStudents,
  destroyStudents,
  updateStudents,
} from "../Api/student";
import moment from "moment/moment";
import WestIcon from "@mui/icons-material/West";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Citylist from "../Pages/citylist.json";
import { viewallstaff } from "../Api/staff";
import { firebase } from "../database/firebase";
import { IoIosInformationCircle } from "react-icons/io";
import { viewallCourses } from "../Api/courses";
import { createPayment } from "../Api/payment";
import { createAmount_split } from "../Api/amount_split";

const StudentsInfo = () => {
  const navigate = useNavigate();

  const [rows, setrows] = useState([]);
  const [allrowdata, setallrowdata] = useState([]);

  const [allrows, setallrows] = useState([]);
  const [createstatus, setcreatestatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [editoption, seteditoption] = useState(false);
  const [editid, seteditid] = useState(null);
  const [statelist, setstatelist] = useState([]);
  const [citylist, setcitylist] = useState([]);
  const [staffdata, setstaffdata] = useState([]);
  const [clicked, setclicked] = useState(false);
  const [formdata, setformdata] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    course: "",
    dob: "",
    joiningdate: "",
    qualification: "",
    occupation: "",
    howyoufind: "",
    fees: "",
    paidfees: "",
    mode: "",
    english: "",
    profile: "",
    state: "",
  });
  const [coursedata, setcoursedata] = useState([]);
  const [formdate, setformdate] = useState(null);
  const [todate, settodate] = useState(null);
  const [pricesplit, setpricesplit] = React.useState(null);

  useEffect(() => {
    getStudentdata();
  }, []);
  const getStudentdata = async () => {
    try {
      // Fetch all student data
      const alldata = await ViewAllStudents();

      if (alldata.length !== 0) {
        alldata.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        // Transform data
        const studentobj = alldata.map((student, index) => ({
          id: index + 1,
          name: student.name,
          course: student.course,
          paidfees: Math.round(student.paidfees),
          pendingfees: Math.round(
            Number(student.fees) - Number(student.paidfees)
          ),
          joiningdate: moment(student.createdAt).format("DD-MM-YYYY"),
          data: student.id,
        }));

        // Update state
        setallrows(alldata);
        setrows(studentobj);
        setallrowdata(studentobj);
      }

      // Fetch and set other data
      const staffdata = await viewallstaff();
      setstaffdata(staffdata);

      setstatelist(Citylist.states);

      const allcourse = await viewallCourses();
      setcoursedata(allcourse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
    if (name === "course") {
      var checkdata = await coursedata.filter((data) => {
        return data.id == value;
      });
      if (checkdata.length !== 0) {
        setformdata((values) => ({
          ...values,
          course: checkdata[0].title,
        }));
        setformdata((values) => ({
          ...values,
          fees: checkdata[0].offerprice,
        }));
        setformdata((values) => ({
          ...values,
          paidfees: 0,
        }));
        setpricesplit(null);
      }
    } else {
      setformdata((values) => ({ ...values, [name]: value }));
    }
  };
  const savebtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please enter Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.city.length === 0) {
      toast.error("Please enter City...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.course.length === 0) {
      toast.error("Please enter Cousrse...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.dob.length === 0) {
      toast.error("Please select Date Of birth...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.joiningdate.length === 0) {
      toast.error("Please select Joining Date...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.qualification.length === 0) {
      toast.error("Please enter Qualification...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.occupation.length === 0) {
      toast.error("Please enter Occupation...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.fees.length === 0) {
      toast.error("Please enter Fees Offered...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.paidfees.length === 0) {
      toast.error("Please enter Fees Paid...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.mode.length === 0) {
      toast.error("Please select Mode Of class...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      formdata["password"] = "Test@123";
      var createstudent = await createStudents(formdata);
      if (createstudent.message === "SUCCESS") {
        toast.success("Student Details added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setclicked(false);
        var newobj = {
          notes: "",
          date: formdata.joiningdate,
          cost: Math.round(formdata.paidfees),
          student: createstudent.data.id,
          pendingfees: Math.round(
            Number(formdata.fees) - Number(formdata.paidfees)
          ),
          mode: formdata.mode,
        };
        await createPayment(newobj);
        for (var i = 0; i < pricesplit; i++) {
          if (pricesplit == 1) {
            var newdata = {
              student: createstudent.data.id,
              amount: Math.round(formdata.fees),
              duedate: moment().format("YYYY-MM-DD"),
              status: "true",
            };
            createAmount_split(newdata);
          } else if (pricesplit == 2) {
            var newdata2 = {
              student: createstudent.data.id,
              amount: Math.round(
                Number(formdata.fees) - Math.round(formdata.fees / 2)
              ),
              duedate: moment()
                .add(30 * i, "days")
                .format("YYYY-MM-DD"),
              status: i == 0 ? "true" : "false",
            };
            createAmount_split(newdata2);
          }
        }
        if (pricesplit == 1) {
          var newobj = {
            id: createstudent.data.id,
            pendingfees: 0,
            paidfees: formdata.fees,
          };
          var paymentobj = {
            student: createstudent.data.id,
            cost: formdata.fees,
            date: moment().format("YYYY-MM-DD"),
            pendingfees: 0,
            mode: "Online",
          };
          createPayment(paymentobj);
          updateStudents(newobj);
        } else if (pricesplit == 2) {
          var newobj2 = {
            id: createstudent.data.id,
            pendingfees: Number(formdata.fees / 2),
            paidfees: Number(formdata.fees / 2),
          };
          var paymentobj2 = {
            student: createstudent.data.id,
            cost: Number(formdata.fees / 2),
            date: moment().format("YYYY-MM-DD"),
            pendingfees: Number(formdata.fees / 2),
            mode: "Online",
          };
          createPayment(paymentobj2);
          updateStudents(newobj2);
        }
        setTimeout(() => {
          getStudentdata();
          setcreatestatus(false);
        }, 2000);
        setpricesplit(null);
      } else {
        toast.error(createstudent.message, {
          autoClose: 2000,
          transition: Slide,
        });
        setclicked(false);
        setpricesplit(null);
      }
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "course",
      headerName: "Course",
      width: 250,
    },
    { field: "joiningdate", headerName: "Joining Date", width: 180 },
    { field: "paidfees", headerName: "Paid Fees", width: 150 },
    { field: "pendingfees", headerName: "Pending Fees", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
          <IconButton className="m-5">
            <IoIosInformationCircle
              onClick={() => {
                getdata(params.row.data);
              }}
            />
          </IconButton>
          <IconButton>
            <Edit
              onClick={() => {
                editbtn(params.row.data);
              }}
            />
          </IconButton>
          <IconButton>
            <DeleteIcon
              onClick={() => {
                deletebtn(params.row.data);
              }}
            />
          </IconButton>
        </>
      ),
    },
  ];
  const getdata = (id) => {
    navigate(`/studentDetails/${id}`);
  };
  const editbtn = async (e) => {
    var checkdata = await allrows.filter((data) => {
      return data.id == e;
    });
    if (checkdata.length !== 0) {
      setformdata({
        name: checkdata[0].name === null ? "" : checkdata[0].name,
        email: checkdata[0].email === null ? "" : checkdata[0].email,
        phone: checkdata[0].phone === null ? "" : checkdata[0].phone,
        city: checkdata[0].city === null ? "" : checkdata[0].city,
        course: checkdata[0].course === null ? "" : checkdata[0].course,
        dob: checkdata[0].dob === null ? "" : checkdata[0].dob,
        joiningdate:
          checkdata[0].joiningdate === null ? "" : checkdata[0].joiningdate,
        qualification:
          checkdata[0].qualification === null ? "" : checkdata[0].qualification,
        occupation:
          checkdata[0].occupation === null ? "" : checkdata[0].occupation,
        howyoufind:
          checkdata[0].howyoufind === null ? "" : checkdata[0].howyoufind,
        fees: checkdata[0].fees === null ? "" : checkdata[0].fees,
        paidfees: checkdata[0].paidfees === null ? "" : checkdata[0].paidfees,
        mode: checkdata[0].mode === null ? "" : checkdata[0].mode,
        english: checkdata[0].english === null ? "" : checkdata[0].english,
      });
    }
    seteditoption(true);
    setcreatestatus(true);
    seteditid(checkdata[0].id);
    setIsOpen(false);
    var checkdatanew = await Citylist.states.filter((data) => {
      return data.state == checkdata[0].state;
    });
    setformdata((values) => ({ ...values, state: checkdata[0].state }));
    if (checkdatanew.length !== 0) {
      setcitylist(checkdatanew[0].districts);
    }
    document.getElementById("state").value = checkdata[0].state;
    setTimeout(() => {
      document.getElementById("city").value = checkdata[0].city;
    }, 100);
  };
  const updatebtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please enter Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.city.length === 0) {
      toast.error("Please enter City...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.course.length === 0) {
      toast.error("Please enter Cousrse...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.dob.length === 0) {
      toast.error("Please select Date Of birth...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.joiningdate.length === 0) {
      toast.error("Please select Joining Date...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.qualification.length === 0) {
      toast.error("Please enter Qualification...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.fees.length === 0) {
      toast.error("Please enter Fees Offered...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.paidfees.length === 0) {
      toast.error("Please enter Fees Paid...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.mode.length === 0) {
      toast.error("Please select Mode Of class...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      formdata["id"] = editid;
      var updatedata = await updateStudents(formdata);
      if (updatedata === "Updated Successfully") {
        toast.success("Student Details Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setclicked(false);
        setTimeout(() => {
          getStudentdata();
          setcreatestatus(false);
          seteditoption(false);
          seteditid(null);
          setIsOpen(false);
          setformdata({
            name: "",
            email: "",
            phone: "",
            city: "",
            state: "",
            course: "",
            dob: "",
            joiningdate: "",
            qualification: "",
            occupation: "",
            howyoufind: "",
            fees: "",
            paidfees: "",
            mode: "",
            english: "",
          });
          setpricesplit(null);
        }, 2000);
      }
    }
  };
  const deletebtn = async (e) => {
    var result = confirm("Are you sure to delete this student?");
    if (result === true) {
      var deletedata = await destroyStudents({ id: e });
      if (deletedata === "Deleted Successfully") {
        toast.success("Student Deleted Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        getStudentdata();
      }
    }
  };
  const changestate = async (e) => {
    var checkdata = await Citylist.states.filter((data) => {
      return data.state == e.target.value;
    });
    if (checkdata.length !== 0) {
      setcitylist(checkdata[0].districts);
    }
    setformdata((values) => ({
      ...values,
      state: e.target.value,
    }));
  };
  const geturl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Pofile uploaded successfully...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, profile: imgurl1 }));
      }
    }
  };
  const searchuser = async (e) => {
    if (e.length !== 0) {
      if (allrowdata.length !== 0) {
        const filterdata = allrowdata.filter((skill) => {
          return skill.name.toLowerCase().includes(e.toLowerCase());
        });
        setrows(filterdata);
      } else {
        setrows([]);
      }
    } else {
      setrows(allrowdata);
    }
  };
  const searchbtn = async () => {
    if (formdate == null) {
      toast.error("Please Select From Date...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (todate == null) {
      toast.error("Please Select To Date...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      const filterdata = allrowdata.filter((skill) => {
        return (
          skill.joiningdate >= moment(formdate).format("DD-MM-YYYY") &&
          skill.joiningdate <= moment(todate).format("DD-MM-YYYY")
        );
      });
      setrows(filterdata);
    }
  };
  const changesplit = (e) => {
    if (e.target.value == 1) {
      setformdata((values) => ({
        ...values,
        paidfees: Math.round(formdata.fees),
      }));
    } else {
      setformdata((values) => ({
        ...values,
        paidfees: Math.round(formdata.fees / 2),
      }));
    }
    setpricesplit(e.target.value);
  };
  return (
    <div>
      {createstatus === false ? (
        <div className="w-full px-14 min-h-screen">
          <div className="flex justify-between py-5 items-center">
            <h1 className="text-2xl py-4 font-black">List of All Students</h1>
            <button
              className="bg-black text-white rounded w-[10rem] font-md py-4"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Create
            </button>

            {isOpen && (
              <ul className="absolute h-max	p-2 w-[15rem] flex flex-col mt-40 right-24 list-none z-10 text-xl text-white bg-slate-700 rounded-xl">
                <li
                  className="mt-2 cursor-pointer hover:text-gray-500"
                  onClick={() => {
                    setcreatestatus(true);
                    setIsOpen(false);
                    setformdata({
                      name: "",
                      email: "",
                      phone: "",
                      city: "",
                      state: "",
                      course: "",
                      dob: "",
                      joiningdate: "",
                      qualification: "",
                      occupation: "",
                      howyoufind: "",
                      fees: "",
                      paidfees: "",
                      mode: "",
                      english: "",
                    });
                  }}
                >
                  Create Manually
                </li>
                <li
                  className="mt-2 cursor-pointer hover:text-gray-500"
                  onClick={() => {
                    window.location.replace(`/#/create_by_link`);
                  }}
                >
                  Create through link
                </li>
                <li
                  className="mt-2 cursor-pointer hover:text-gray-500"
                  onClick={() => {
                    window.location.replace(`/#/add_existing_student`);
                  }}
                >
                  Add Existing student
                </li>
              </ul>
            )}
          </div>
          <div className="flex w-full items-center justify-between">
            <input
              type="text"
              placeholder="Search Student Name"
              className="border rounded py-2 mb-5 w-[30%] border-gray-400 px-5 mt-5"
              onChange={(e) => {
                searchuser(e.target.value);
              }}
            />
            <div className="flex gap-2 items-center">
              <div>
                <h6>From Date</h6>
                <input
                  type="date"
                  id="fromdate"
                  placeholder="Search Student Name"
                  className="border rounded py-2 mb-5 w-full border-gray-400 px-5"
                  onChange={(e) => {
                    setformdate(e.target.value);
                  }}
                />
              </div>
              <div>
                <h6>To Date</h6>
                <input
                  type="date"
                  id="todate"
                  placeholder="Search Student Name"
                  className="border rounded py-2 mb-5 w-full border-gray-400 px-5"
                  onChange={(e) => {
                    settodate(e.target.value);
                  }}
                />
              </div>
              <button
                className="bg-black text-white rounded w-[5rem] font-md py-2"
                onClick={searchbtn}
              >
                Search
              </button>
              <button
                className="bg-white text-black border border-black rounded w-[5rem] font-md py-2"
                onClick={() => {
                  setrows(allrowdata);
                  settodate(null);
                  setformdate(null);
                  document.getElementById("todate").value = "";
                  document.getElementById("fromdate").value = "";
                }}
              >
                Reset
              </button>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              align={"center"}
              pageSizeOptions={[10, 20, 50, 100]}
              // checkboxSelection
            />
          </div>
        </div>
      ) : (
        <div className="px-14 min-h-screen">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setcreatestatus(false);
            }}
          >
            <WestIcon />
            {editoption === true ? (
              <h1 className="text-2xl py-4 font-black">Edit Admission Form</h1>
            ) : (
              <h1 className="text-2xl py-4 font-black">Admission Form</h1>
            )}
          </div>
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Name
                  </h1>
                  <input
                    placeholder="Name"
                    type="text"
                    name="name"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.name}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Email
                  </h1>
                  <input
                    placeholder="Email"
                    type="text"
                    name="email"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.email}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Phone Number
                  </h1>
                  <input
                    placeholder="Phone Number"
                    type="text"
                    name="phone"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.phone}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Profile
                  </h1>
                  <input
                    placeholder="Phone Number"
                    type="file"
                    name="phone"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={geturl}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    State
                  </h1>
                  <select
                    name="state"
                    id="state"
                    className="border-2 p-2 w-full py-2 rounded"
                    required=""
                    defaultValue={formdata.state}
                    onChange={changestate}
                  >
                    <option value="">Select State</option>
                    {statelist.length !== 0
                      ? statelist.map((data, index) => (
                          <option value={data.state} key={index}>
                            {data.state}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    City
                  </h1>
                  <select
                    name="city"
                    id="city"
                    className="border-2 p-2 w-full py-2 rounded"
                    required=""
                    defaultValue={formdata.city}
                    onChange={handleChange}
                  >
                    <option value="">Select City</option>
                    {citylist.length !== 0
                      ? citylist.map((data, index) => (
                          <option
                            value={data}
                            key={index}
                            selected={formdata.city == data}
                          >
                            {data}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Course
                  </h1>
                  <select
                    name="course"
                    id=""
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.course}
                  >
                    <option value="">Select Course</option>
                    {coursedata.length !== 0
                      ? coursedata.map((data, index) => (
                          <option value={data.id} key={index}>
                            {data.title}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Date Of Birth
                  </h1>
                  <input
                    placeholder="Email"
                    type="date"
                    name="dob"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.dob}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Joining Date
                  </h1>
                  <input
                    placeholder="Joining Date"
                    type="date"
                    name="joiningdate"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.joiningdate}
                  />
                </div>
              </div>
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Qualification
                  </h1>
                  <input
                    placeholder="Qualification"
                    type="text"
                    name="qualification"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.qualification}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Occupation</h1>
                  <select
                    name="occupation"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.occupation}
                  >
                    <option>Select Occupation</option>
                    <option value="Student">Student</option>
                    <option value="Unemployed">Unemployed</option>
                    <option value="Working Professional">
                      Working Professional
                    </option>
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg">How you find us ?</h1>
                  <select
                    name="howyoufind"
                    id=""
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.howyoufind}
                  >
                    <option value="">Select</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Google">Google</option>
                    <option value="Local Advertisement">
                      Local Advertisement
                    </option>
                    <option value="Recommendation from a friend">
                      Recommendation from a friend
                    </option>
                    <option value="Others">Others</option>
                  </select>
                </div>

                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Fees Offered
                  </h1>
                  <input
                    placeholder="Fees Offered"
                    type="number"
                    name="fees"
                    disabled
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.fees}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Preferred course location
                  </h1>
                  <select
                    name="location"
                    id=""
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                  >
                    <option value="">Select location</option>
                    <option value="Coimbatore">Coimbatore</option>
                    <option value="Sivakasi">Sivakasi</option>
                  </select>
                </div>
                {formdata.location == "Sivakasi" ? (
                  <div className="py-3">
                    <div className="flex gap-2 items-center">
                      <h1 className="text-lg">Select Amount Split </h1>
                      {formdata.fees == 0 ? null : (
                        <p>(Course Amount : {formdata.fees})</p>
                      )}
                    </div>
                    <div className="flex gap-4 mt-2">
                      <div>
                        <input
                          type="radio"
                          id="1 split"
                          name="split"
                          value="1"
                          checked={pricesplit == 1}
                          onChange={changesplit}
                        />
                        <label for="1 split" className="ml-2">
                          1 split
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="2 split"
                          name="split"
                          value="2"
                          checked={pricesplit == 2}
                          onChange={changesplit}
                        />
                        <label for="2 split" className="ml-2">
                          2 split
                        </label>
                      </div>
                    </div>
                    <div className="py-3">
                      {pricesplit == 1 ? (
                        <>
                          <table className="w-full text-center">
                            <tr>
                              <th>Type</th>
                              <th>Date</th>
                              <th>Amount</th>
                            </tr>
                            <tr>
                              <td>1st Payment</td>
                              <td>{moment().format("DD-MM-YYYY")}</td>
                              <td>
                                ₹{Math.round(formdata.fees)}
                                /-
                              </td>
                            </tr>

                            <tr>
                              <td colspan="2">Total</td>
                              <td>
                                ₹{Math.round(formdata.fees)}
                                /-{" "}
                                {/* <span className="text-green-500">(10% OFF)</span> */}
                              </td>
                            </tr>
                          </table>
                        </>
                      ) : null}
                      {pricesplit == 2 ? (
                        <>
                          <table className="w-full text-center">
                            <tr>
                              <th>Type</th>
                              <th>Date</th>
                              <th>Amount</th>
                            </tr>
                            <tr>
                              <td>1st Payment</td>
                              <td>{moment().format("DD-MM-YYYY")}</td>
                              <td>
                                ₹{Math.round(formdata.fees / 2)}
                                /-
                              </td>
                            </tr>
                            <tr>
                              <td>2nd Payment</td>
                              <td>
                                {moment().add(30, "days").format("DD-MM-YYYY")}
                              </td>
                              <td>
                                ₹{Math.round(formdata.fees / 2)}
                                /-
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">Total</td>
                              <td>
                                ₹{Math.round(formdata.fees)}
                                /-{" "}
                                {/* <span className="text-green-500">(5% OFF)</span> */}
                              </td>
                            </tr>
                          </table>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : formdata.location == "Coimbatore" ? (
                  <>
                    <div className="flex gap-2 items-center">
                      <h1 className="text-lg">Select Amount Split </h1>
                      {formdata.fees == 0 ? null : (
                        <p>(Course Amount : {formdata.fees})</p>
                      )}
                    </div>
                    <div className="flex gap-4 mt-2">
                      <div>
                        <input
                          type="radio"
                          id="1 split"
                          name="split"
                          value="1"
                          checked={pricesplit == 1}
                          onChange={changesplit}
                        />
                        <label for="1 split" className="ml-2">
                          1 split
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="EMI"
                          name="split"
                          value="EMI"
                          checked={pricesplit == "EMI"}
                          onChange={changesplit}
                        />
                        <label for="EMI" className="ml-2">
                          EMI
                        </label>
                      </div>
                    </div>
                    <div className="py-3">
                      {pricesplit == "EMI" ? (
                        <>
                          <table className="w-full text-center">
                            <tr>
                              <th>Advance Amount</th>
                              <td>
                                ₹
                                {Math.round(
                                  Number(formdata.fees) -
                                    (Number(formdata.fees) * 0) / 100
                                )}
                                /-
                              </td>
                            </tr>
                            <tr>
                              <th>Advance Amount</th>
                              <td>1st Payment</td>
                            </tr>
                          </table>
                        </>
                      ) : null}
                    </div>
                  </>
                ) : null}

                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Fees Paid
                  </h1>
                  <input
                    placeholder="Fees Paid"
                    type="number"
                    name="paidfees"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    disabled
                    defaultValue={formdata.paidfees}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Mode Of class
                  </h1>
                  <select
                    name="mode"
                    id=""
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.mode}
                  >
                    <option value="">Select Mode</option>
                    <option value="Online">Online</option>
                    <option value="Offline">Offline</option>
                  </select>
                </div>

                {/* <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Spoken English Training (INR 3,800/-)
                  </h1>
                  <select
                    name="english"
                    id="english"
                  className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.english}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div> */}
              </div>
            </div>
            <div className="py-8">
              {editoption === true ? (
                clicked === true ? (
                  <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                    Please Wait...
                  </button>
                ) : (
                  <button
                    className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                    onClick={updatebtn}
                  >
                    Update
                  </button>
                )
              ) : clicked === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                  Please Wait...
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={savebtn}
                >
                  Save & Create Next
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default StudentsInfo;
